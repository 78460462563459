import { getCurrencyFromLanguage, getInstanceLanguage } from '../helpers'
import {
  AccordionEnum,
  CalendarModeEnum,
  CalendarStateEnum,
  GuestsFormData,
  HotelDialog,
  ICalendar,
  IChild,
  ICurrency,
  IDestination,
  IHotel,
  IImages,
  ILanguage,
  ILoaders,
  ILoyaltyProgram,
  INotification,
  IPet,
  IPrice,
  IRoomType,
  IRouteQueryParamsWithDynamicParams,
  IStepper,
  ITravelDate,
  IValidationState,
  IVisitCategory,
  IVisitType,
  PaymentEnum,
  PersonalFormData,
  RoomTypeDialog,
  ServiceFormData,
  SummaryFormData,
  VisitTypeDialog,
} from './data'

export const DEFAULT_LANGUAGE = getInstanceLanguage()

export interface BookingFormState {
  accordion: AccordionEnum | null
  affiliate: {
    affilUserName: string | null
    timestamp: number
  }
  appInitialized: boolean
  appInitializedAndValidated: boolean
  calendar: ICalendar
  children: IChild[]
  childrenCount: number
  currency: ICurrency
  demand: boolean
  destination: IDestination | null
  destinations: IDestination[]
  guest: number | null
  guestsData: GuestsFormData
  hotel: IHotel | null
  hotelDialog: HotelDialog
  hotels: IHotel[]
  images: IImages
  isDeletingQueryParameter: boolean
  isFetching: boolean
  isFetchingHotelRoomTypes: boolean
  isFetchingHotels: boolean
  isFetchingVisitCategories: boolean
  isFormFilled: boolean
  language: ILanguage
  languageInitialized: boolean
  loaders: ILoaders
  lowestPrice: IPrice | null
  loyaltyProgram: ILoyaltyProgram[]
  notifications: INotification[]
  onlyDemand: boolean
  personalData: PersonalFormData
  pets: IPet[]
  petsCount: number
  price: IPrice | null
  roomType: IRoomType | null
  roomTypeDialog: RoomTypeDialog
  roomTypes: IRoomType[]
  routeQueryParams: IRouteQueryParamsWithDynamicParams
  servicesData: ServiceFormData
  stepper: IStepper
  summaryData: SummaryFormData
  travelDate: ITravelDate
  validationState: IValidationState
  visitCategories: IVisitCategory[]
  visitType: IVisitType | null
  visitTypeCategory: IVisitCategory | null
  visitTypeDialog: VisitTypeDialog
}

export const affiliateInitialData = {
  affilUserName: null,
  timestamp: Date.now(),
}

export const defaultInitialState: BookingFormState = {
  accordion: null,
  affiliate: affiliateInitialData,
  appInitialized: false,
  appInitializedAndValidated: false,
  calendar: {
    checkout: [],
    data: [],
    date: new Date(),
    datesCache: {},
    isDidInvalid: false,
    isFetching: false,
    mode: CalendarModeEnum.FROM_DATE,
    price: null,
    state: CalendarStateEnum.INITIAL,
    travelDate: {
      from: null,
      to: null,
    },
  },
  children: [],
  childrenCount: 0,
  currency: getCurrencyFromLanguage(DEFAULT_LANGUAGE),
  demand: false,
  destination: null,
  destinations: [],
  guest: null,
  guestsData: {
    guests: [],
  },
  hotel: null,
  hotelDialog: {
    data: null,
    isDidInvalid: false,
    isFetching: false,
  },
  hotels: [],
  images: {
    loaded: 0,
    loading: 0,
  },
  isDeletingQueryParameter: false,
  isFetching: false,
  isFetchingHotelRoomTypes: false,
  isFetchingHotels: false,
  isFetchingVisitCategories: false,
  isFormFilled: false,
  language: DEFAULT_LANGUAGE,
  languageInitialized: false,
  loaders: {
    booking: false,
  },
  lowestPrice: null,
  loyaltyProgram: [],
  notifications: [],
  onlyDemand: false,
  personalData: {
    birth_date: '',
    city: '',
    country: '',
    email: '',
    first_name: '',
    gender: null,
    house_number: '',
    last_name: '',
    phone: '',
    street: '',
    zip_code: '',
  },
  pets: [],
  petsCount: 0,
  price: null,
  roomType: null,
  roomTypeDialog: {
    data: null,
    isDidInvalid: false,
    isFetching: false,
  },
  roomTypes: [],
  routeQueryParams: {},
  servicesData: {
    loyaltyPrograms: [],
    note: null,
  },
  stepper: {
    step: 1,
    totalStep: 5,
  },
  summaryData: {
    agree: false,
    marketing: false,
    payment: PaymentEnum.Bank, // Default payment method
  },
  travelDate: {
    from: null,
    to: null,
  },
  validationState: 'DESTINATIONS',
  visitCategories: [],
  visitType: null,
  visitTypeCategory: null,
  visitTypeDialog: {
    data: null,
    isDidInvalid: false,
    isFetching: false,
  },
}
