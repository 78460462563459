import { format } from 'date-fns'
import { DATE_FORMAT_YYYY_MM_DD } from '../constants'
import { isGuestDefined } from '../helpers'
import { GuestFormData, IChild, IPet, ServiceFormData } from '../store/data'

export const prepareGuestDataForBookingRequest = (
  guestsData: GuestFormData[],
  servicesFormData: ServiceFormData
) => {
  return guestsData.map((guest, index) => {
    // A guest is optional for a demand, but in this case, we must provide all mandatory data as placeholders.
    const guestDefined = isGuestDefined(guest)

    return {
      ...(!guestDefined
        ? {
            birth_date: format(
              new Date(Math.floor(new Date().getTime() / 1000)),
              DATE_FORMAT_YYYY_MM_DD
            ),
            first_name: 'Guest',
            gender: 'male',
            last_name: `${index + 1}`,
          }
        : {
            birth_date: format(new Date(guest.birth_date!), DATE_FORMAT_YYYY_MM_DD),
            first_name: guest.first_name,
            gender: guest.gender,
            last_name: guest.last_name,
          }),
      loyalty_program_code: servicesFormData.loyaltyPrograms?.[index]?.code || '',
      loyalty_program_id: servicesFormData.loyaltyPrograms?.[index]?.id || '',
    }
  })
}

export const createCustomerNote = (note: string | null, children: IChild[], pets: IPet[]) => {
  const parts: string[] = []

  if (note?.trim()) {
    parts.push(note.trim())
  }

  if (children.length > 0) {
    const ages = children
      .map((child) => child.age)
      .filter((age): age is number => age !== null) // odstranění null hodnot a type guard
      .sort((a, b) => a - b)
      .join(', ')
    if (ages) {
      parts.push(`Kids ages: ${ages}`)
    }
  }

  if (pets.length > 0) {
    const species = pets
      .map((pet) => pet.species)
      .filter((species): species is string => species !== null) // odstranění null hodnot a type guard
      .sort()
      .join(', ')
    if (species) {
      parts.push(`Pets: ${species}`)
    }
  }

  return parts.length > 0 ? parts.join(' | ') : null
}
