import { ILang, ILanguage, LanguageEnum } from './store/data'

export const layoutName = {
  frantiskovylazne: 'frantiskovylazne',
  spabookingCs: 'spabookingCs',
  spabookingDe: 'spabookingDe',
  spabookingDefault: 'spabookingDefault',
} as const

export type LayoutName = keyof typeof layoutName

export interface ILayoutConfig {
  colors: {
    primary: {
      highlight: string
      main: string
    }
    secondary: {
      highlight: string
      main: string
    }
    theme: {
      dark: string
      gradient: {
        from: string
        to: string
      }
      light: string
      main: string
      transparent: string
    }
  }
  copyright: string
  destinationId: number | null
  id: LayoutName
  instanceLanguage?: ILanguage
  instanceLanguageDomains?: Partial<Record<ILanguage, string>>
  links: {
    contact: Record<keyof ILang, string | null>
    gdpr: Record<keyof ILang, string | null>
    homepage: Record<keyof ILang, string | null>
    tos: Record<keyof ILang, string | null>
  }
  logo: string
  supportedLocales: ILanguage[]
  title: string
}

const spabookingDefaultConfig: Omit<ILayoutConfig, 'id'> = {
  colors: {
    primary: {
      highlight: '#BEFFD4',
      main: '#00743C',
    },
    secondary: {
      highlight: '#793A00',
      main: '#FFCA0F',
    },
    theme: {
      dark: '#6f8c23',
      gradient: {
        from: '#7e9f26',
        to: '#a3c74d',
      },
      light: '#a8cb59',
      main: '#8fb42c',
      transparent: '#8FB42C19',
    },
  },
  copyright: 'SpaBooking.cz',
  destinationId: null,
  links: {
    contact: {
      cs_CZ: 'https://spabooking.cz/pages/contact',
      de: 'https://spabooking.cz/de/pages/contact',
      en: null,
      ru: null,
    },
    gdpr: {
      cs_CZ: 'https://spabooking.cz/pages/policies',
      de: 'https://spabooking.cz/de/pages/policies',
      en: null,
      ru: null,
    },
    homepage: {
      cs_CZ: 'https://spabooking.cz/',
      de: 'https://spabooking.cz/de',
      en: null,
      ru: null,
    },
    tos: {
      cs_CZ: 'https://spabooking.cz/pages/policies',
      de: 'https://spabooking.cz/de/pages/policies',
      en: null,
      ru: null,
    },
  },
  logo: '/logo/spabooking.png',
  supportedLocales: [LanguageEnum.CS, LanguageEnum.DE, LanguageEnum.EN],
  title: 'Spa Booking',
}

export const layoutConfig: Record<LayoutName, ILayoutConfig> = {
  frantiskovylazne: {
    colors: {
      primary: {
        highlight: '#BEFFD4',
        main: '#00743C',
      },
      secondary: {
        highlight: '#793A00',
        main: '#FFCA0F',
      },
      theme: {
        dark: '#A37719',
        gradient: {
          from: '#B5841C',
          to: '#AE7100',
        },
        light: '#CF9720',
        main: '#B37D11',
        transparent: '#B37D111A',
      },
    },
    copyright: 'Lázně Františkovy Lázně a.s.',
    destinationId: 1,
    id: layoutName.frantiskovylazne,
    links: {
      contact: {
        cs_CZ: 'https://www.frantiskovylazne.cz/cs/kontakty',
        de: 'https://www.frantiskovylazne.cz/de/kontakt',
        en: 'https://www.frantiskovylazne.cz/en/contacts',
        ru: 'https://www.frantiskovylazne.cz/ru/kontakty',
      },
      gdpr: {
        cs_CZ: 'https://www.frantiskovylazne.cz/cs/zpracovani-osobnich-udaju',
        de: 'https://www.frantiskovylazne.cz/de/verarbeitung-personenbezogener-daten',
        en: 'https://www.frantiskovylazne.cz/en/personal-data-processing',
        ru: 'https://www.frantiskovylazne.cz/ru/obrabotka-personalnykh-dannykh',
      },
      homepage: {
        cs_CZ: 'https://www.frantiskovylazne.cz/cs',
        de: 'https://www.frantiskovylazne.cz/de',
        en: 'https://www.frantiskovylazne.cz/en',
        ru: 'https://www.frantiskovylazne.cz/ru',
      },
      tos: {
        cs_CZ: 'https://www.frantiskovylazne.cz/cs/vseobecne-obchodni-podminky',
        de: 'https://www.frantiskovylazne.cz/de/allgemeinen-vertragsbedingungen',
        en: 'https://www.frantiskovylazne.cz/en/general-terms-and-conditions',
        ru: 'https://www.frantiskovylazne.cz/ru/obshhie-kommercheskie-uslovija',
      },
    },
    logo: '/logo/frantisek.svg',
    supportedLocales: [LanguageEnum.CS, LanguageEnum.DE, LanguageEnum.EN],
    title: 'Lázně Františkovy Lázně a.s.',
  },
  spabookingCs: {
    ...spabookingDefaultConfig,
    id: layoutName.spabookingCs,
    instanceLanguage: LanguageEnum.CS,
    instanceLanguageDomains: {
      [LanguageEnum.CS]: 'https://rezervace.spabooking.cz/',
      [LanguageEnum.DE]: 'https://reservierung.spabooking.de/',
    },
    supportedLocales: [LanguageEnum.CS, LanguageEnum.DE],
  },
  spabookingDe: {
    ...spabookingDefaultConfig,
    copyright: 'SpaBooking.de',
    id: layoutName.spabookingDe,
    instanceLanguage: LanguageEnum.DE,
    instanceLanguageDomains: {
      [LanguageEnum.CS]: 'https://rezervace.spabooking.cz/',
      [LanguageEnum.DE]: 'https://reservierung.spabooking.de/',
    },
    supportedLocales: [LanguageEnum.CS, LanguageEnum.DE],
  },
  spabookingDefault: {
    ...spabookingDefaultConfig,
    id: layoutName.spabookingDefault,
  },
}

export const getLayoutConfig = (): ILayoutConfig => {
  try {
    const layout = process.env.NEXT_PUBLIC_LAYOUT_NAME as LayoutName

    if (!layoutConfig[layout]) {
      console.warn(`Layout "${layout}" not found, using default`)
      return layoutConfig[layoutName.spabookingDefault]
    }

    return layoutConfig[layout]
  } catch (error) {
    console.warn('Error getting layout config, using default:', error)
    return layoutConfig[layoutName.spabookingDefault]
  }
}
