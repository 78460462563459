import { AxiosResponse } from 'axios'
import { format, isDate } from 'date-fns'
import { devtools, persist } from 'zustand/middleware'
import { createStore } from 'zustand/vanilla'
import {
  getCalendarDates,
  getDateKeysForCache,
  getDatesForCalendarDate,
  getRangeForCalendarDatesRequest,
  parseDateString,
} from '@utils/dates'
import { customizeError, MESSAGE_LEVELS } from '@utils/sentry'
import {
  getBookingConfiguration,
  getDatesApiRequest,
  getDatesCheckoutApiRequest,
  getHotelApiRequest,
  getHotelsApiRequest,
  getLowestPrice,
  getLoyaltyProgram,
  getPriceApiRequest,
  getVisitCategoriesApiRequest,
  sendBookingApiRequest,
} from '../api'
import { handleApiError } from '../api/axios'
import { createCustomerNote, prepareGuestDataForBookingRequest } from '../api/helpers'
import { DATE_FORMAT_YYYY_MM_DD } from '../constants'
import { BasicContactFormValues } from '../forms/guests/useBasicContactForm'
import { GuestFormValues } from '../forms/guests/useGuestsForm'
import { PersonalDataFormValues } from '../forms/guests/usePersonalDataForm'
import { ServicesFormValues } from '../forms/services/useServicesForm'
import {
  getCurrencyFromLanguage,
  getPriceOrNull,
  getValidDestinationOrNull,
  getValidGuestNumberOrNull,
  PathEnum,
  redirectTo,
  sanitizeBookingParams,
} from '../helpers'
import {
  AccordionEnum,
  CalendarModeEnum,
  CalendarStateEnum,
  GuestsFormData,
  IBookingRequest,
  IBookingResponse,
  IChild,
  ICurrency,
  IDate,
  IDateCheckout,
  IDatesCheckoutParamsQuery,
  IDatesParamsQuery,
  IDateWithCache,
  Identificator,
  IDestination,
  IGuest,
  IHotel,
  IHotelDetailParamsQuery,
  IHotelsParamsQuery,
  IInitDataResponse,
  IInitDataSuccessPayload,
  ILanguage,
  ILoaders,
  ILoyaltyProgram,
  INotification,
  IPet,
  IPrice,
  IRoomType,
  IRouteQueryParams,
  IRouteQueryParamsWithDynamicParams,
  ITravelDate,
  IValidationState,
  IVisitCategory,
  IVisitType,
  IVisitTypeCategoryParamsQuery,
  Loaders,
  PersonalFormData,
  ServiceFormData,
  SummaryFormData,
} from './data'
import { affiliateInitialData, BookingFormState, defaultInitialState } from './state'

// persisted data in local storage expiration
const MAX_DAYS = process.env.NEXT_PUBLIC_AFFILIATE_EXPIRE_DAYS
  ? parseInt(process.env.NEXT_PUBLIC_AFFILIATE_EXPIRE_DAYS)
  : 30
const EXPIRATION_TIME = MAX_DAYS * 24 * 60 * 60 * 1000

interface BookingFormActions {
  addLoadedImage: () => void
  addNotification: (notification: INotification) => void
  changeAccordion: (value: AccordionEnum | null) => void
  changeCalendarDate: (value: Date) => void
  changeChildrenCount: (value: number) => void
  changeCurrency: (newCurrency: ICurrency) => void
  changeDestination: (value: IDestination | null) => void
  changeGuest: (value: number | null) => void
  changeHotel: (value: IHotel | null) => void
  changeLanguage: (newLanguage: ILanguage) => void
  changePetsCount: (value: number) => void
  changeRoomType: (value: IRoomType | null) => void
  changeValidationState: (value: IValidationState) => void
  changeVisitType: (value: IVisitType | null) => void
  changeVisitTypeCategory: (value: IVisitCategory | null) => void
  clearExpiredAffiliateData: () => void
  getBookingConfiguration: (
    params: IRouteQueryParams,
    callValidatedGuest: (guest: number | null, destination: IDestination | null) => Promise<void>
  ) => Promise<void>
  loadDates: (params: IDatesParamsQuery) => Promise<void>
  loadDatesCheckout: (params: IDatesCheckoutParamsQuery) => Promise<void>
  loadHotel: (hotelId: Identificator) => Promise<void>
  loadHotelRoomTypes: (hotelId: Identificator, params: IHotelDetailParamsQuery) => Promise<void>
  loadHotels: (params: IHotelsParamsQuery) => Promise<void>
  loadLowestPrice: (params: URLSearchParams) => Promise<void>
  loadLoyaltyProgram: () => Promise<void>
  loadPrice: (params: URLSearchParams) => Promise<void>
  loadVisitCategories: (params: IVisitTypeCategoryParamsQuery) => Promise<void>
  removeNotification: (notificationToRemove: INotification) => void
  resetApplication: () => void
  resetCalendar: () => void
  saveChildren: (data: IChild[]) => void
  saveGuests: (data: GuestFormValues) => void
  savePets: (data: IPet[]) => void
  sendBookingRequest: () => Promise<void>
  setAffiliateUsername: (affilUserName: string) => void
  setAppInitialized: (isAppInitialized: boolean) => void
  setAppInitializedAndValidated: (isAppInitializedAndValidated: boolean) => void
  setCalendarMode: (mode: CalendarModeEnum) => void
  setCalendarTravelDate: (calendarTravelDateData: ITravelDate) => Promise<void>
  setDeletingQueryParameter: (isDeleting: boolean) => void
  setDemand: (isDemand: boolean) => void
  setDestinations: (destinations: IDestination[]) => void
  setHotelDialogData: (data: IHotel | null) => void
  setInitData: (initDataBookingConfigResponse: IInitDataSuccessPayload) => void
  setIsFetching: (isFetching: boolean) => void
  setIsFormFilled: (isFormFilled: boolean) => void
  setLoader: (loader: keyof ILoaders) => void
  setLoadingImages: (loadingImagesCount: number) => void
  setPersonalData: (data: PersonalDataFormValues | BasicContactFormValues) => void
  setRoomTypeDialogData: (data: IRoomType | null) => void
  setRouteQueryParams: (routeQueryParams: IRouteQueryParamsWithDynamicParams) => void
  setServicesData: (data: ServicesFormValues) => void
  setStep: (value: number) => void
  setSummaryData: (data: SummaryFormData) => void
  setTravelDate: (travelDateData: ITravelDate) => Promise<void>
  setVisitTypeDialogData: (data: IVisitType | null) => void
}

export type BookingFormStore = BookingFormState & BookingFormActions

export const initBookingFormStore = (): BookingFormState => {
  return defaultInitialState
}

export const createBookingFormStore = (initState: BookingFormState = defaultInitialState) => {
  return createStore<BookingFormStore>()(
    devtools(
      persist(
        (set, get) => ({
          ...initState,
          addLoadedImage: () => {
            set(
              (state) => ({
                images: { ...state.images, loaded: ++state.images.loaded },
              }),
              undefined,
              'addLoadedImage'
            )
          },
          addNotification: (notification: INotification) =>
            set(
              (state) => ({
                notifications: [...state.notifications, notification],
              }),
              undefined,
              'addNotification'
            ),
          changeAccordion: (value: AccordionEnum | null) =>
            set(
              (state) => ({
                accordion: value,
                images: { ...state.images, loaded: 0 },
              }),
              undefined,
              'changeAccordion'
            ),
          changeCalendarDate: (value: Date) =>
            set(
              (state) => ({
                calendar: {
                  ...state.calendar,
                  date: value,
                  state: CalendarStateEnum.SET,
                },
              }),
              undefined,
              'changeCalendarDate'
            ),
          changeChildrenCount: (value: number) => {
            set(
              {
                childrenCount: value,
              },
              undefined,
              'changeChildrenCount'
            )
          },
          changeCurrency: (newCurrency) =>
            set(
              {
                currency: newCurrency,
              },
              undefined,
              'changeCurrency'
            ),
          changeDestination: (value: IDestination | null) =>
            set(
              (state) => ({
                calendar: {
                  ...state.calendar,
                  datesCache: {},
                },
                destination: value,
              }),
              undefined,
              'changeDestination'
            ),
          changeGuest: (value: number | null) => {
            const guest = getValidGuestNumberOrNull(value)

            const {
              calendar: { datesCache: datesCacheInStore },
              guest: guestInStore,
            } = get() as BookingFormStore

            const newDatesCache = guest !== guestInStore ? {} : datesCacheInStore

            set(
              (state) => ({
                calendar: {
                  ...state.calendar,
                  datesCache: newDatesCache,
                },
                guest,
              }),
              undefined,
              'changeGuest'
            )

            if (guest === null) {
              set(
                {
                  children: [],
                },
                undefined,
                'clearChildren'
              )
              set(
                {
                  pets: [],
                },
                undefined,
                'clearPets'
              )
            }
          },
          changeHotel: (value: IHotel | null) => {
            const hotel = value

            set(
              (state) => ({
                calendar: {
                  ...state.calendar,
                  datesCache: {},
                  state: CalendarStateEnum.INITIAL,
                  travelDate: {
                    from: null,
                    to: null,
                  },
                },
                hotel: hotel ? { ...hotel, id: +hotel.id } : null,
                roomType: null,
                travelDate: {
                  from: null,
                  to: null,
                },
              }),
              undefined,
              'changeHotel'
            )
          },
          changeLanguage: (newLanguage) =>
            set(
              {
                currency: getCurrencyFromLanguage(newLanguage),
                language: newLanguage,
                languageInitialized: true,
              },
              undefined,
              'changeLanguage'
            ),
          changePetsCount: (value: number) => {
            set(
              {
                petsCount: value,
              },
              undefined,
              'changePetsCount'
            )
          },
          changeRoomType: (value: IRoomType | null) => {
            const roomType = value

            const { guest } = get() as BookingFormStore

            if (guest === null) {
              set(
                (state) => ({
                  calendar: {
                    ...state.calendar,
                    datesCache: {},
                    state: CalendarStateEnum.INITIAL,
                    travelDate: {
                      from: null,
                      to: null,
                    },
                  },
                  guest: roomType?.beds || null,
                  roomType,
                  travelDate: {
                    from: null,
                    to: null,
                  },
                }),
                undefined,
                'changeRoomType'
              )
            } else {
              set(
                (state) => ({
                  calendar: {
                    ...state.calendar,
                    datesCache: {},
                    state: CalendarStateEnum.INITIAL,
                    travelDate: {
                      from: null,
                      to: null,
                    },
                  },
                  roomType,
                  travelDate: {
                    from: null,
                    to: null,
                  },
                }),
                undefined,
                'changeRoomType'
              )
            }
          },
          changeValidationState: (value: IValidationState) =>
            set(
              {
                validationState: value,
              },
              undefined,
              'changeValidationState'
            ),
          changeVisitType: (value: IVisitType | null) => {
            set(
              (state) => ({
                calendar: {
                  ...state.calendar,
                  datesCache: {},
                  state: CalendarStateEnum.INITIAL,
                  travelDate: {
                    from: null,
                    to: null,
                  },
                },
                travelDate: {
                  from: null,
                  to: null,
                },
                visitType: value || null,
              }),
              undefined,
              'changeVisitType'
            )
          },
          changeVisitTypeCategory: (value: IVisitCategory | null) =>
            set(
              (state) => ({
                calendar: {
                  ...state.calendar,
                  datesCache: {},
                },
                visitTypeCategory: value,
              }),
              undefined,
              'changeVisitTypeCategory'
            ),
          clearExpiredAffiliateData: () => {
            const { affiliate } = get() as BookingFormStore
            const currentTime = Date.now()

            if (currentTime - affiliate.timestamp > EXPIRATION_TIME) {
              set(
                {
                  affiliate: affiliateInitialData,
                },
                undefined,
                'clearExpiredAffiliateData'
              )
            }
          },
          getBookingConfiguration: async (
            params,
            callValidatedGuest: (
              guest: number | null,
              destination: IDestination | null
            ) => Promise<void>
          ) => {
            if (params && Object.keys(params).length > 0) {
              try {
                const bookingConfigQueryParams = {
                  date_from: params?.visitStart || null,
                  date_to: params?.visitEnd || null,
                  destination_name: params?.destination || null,
                  guest: params?.guest ? +params.guest : null,
                  hotel_name: params?.hotel || null,
                  lang: params?.lang || null,
                  room_type_category: params?.roomType || null,
                  visit_type_name: params?.visitType || null,
                }

                const { data: bookingConfigData, status }: AxiosResponse<IInitDataResponse> =
                  await getBookingConfiguration(sanitizeBookingParams(bookingConfigQueryParams))

                if (status === 200) {
                  const travelDate: ITravelDate = {
                    from: bookingConfigData.date_from
                      ? parseDateString(bookingConfigData.date_from as string)
                      : null,
                    to: bookingConfigData.date_to
                      ? parseDateString(bookingConfigData.date_to as string)
                      : null,
                  }

                  // If guest is not defined but room_type with beds is defined => fill guest from beds
                  const guest =
                    getValidGuestNumberOrNull(bookingConfigQueryParams.guest) ||
                    (bookingConfigData.room_type?.beds as number) ||
                    null

                  // If the destination from query parameters is undefined or invalid, check if a default destination is specified in the .env file and use that; otherwise, return null.
                  const { destinations, setCalendarTravelDate, setInitData, setTravelDate } =
                    get() as BookingFormStore

                  let requestedDestination = null

                  /**
                   * FIXME (SP2 vs. SP3 check, remove after full migration to SP3)
                   * - /destinations returns all destinations where a stay can be booked,
                   * but if a valid destination comes from query parameters, e.g., Jáchymov, then SP2 /booking will return it
                   */
                  if (bookingConfigData?.destination?.id) {
                    const destinationId = bookingConfigData.destination.id

                    const availableDestination = destinations.find(
                      (destination) => Number(destination.id) === Number(destinationId)
                    )

                    if (availableDestination) {
                      requestedDestination = bookingConfigData.destination
                    }
                  }

                  const destination = getValidDestinationOrNull(destinations, requestedDestination)

                  await callValidatedGuest(guest, destination)

                  setInitData({
                    ...bookingConfigData,
                    date_from: travelDate.from,
                    date_to: travelDate.to,
                    destination,
                    guest,
                  })

                  await setCalendarTravelDate(travelDate)
                  await setTravelDate(travelDate)
                }
              } catch (error: any) {
                const { addNotification } = get() as BookingFormStore
                handleApiErrorAndShowNotification(error, addNotification)
              }
            }
          },
          loadDates: async (params: IDatesParamsQuery) => {
            const requestParams = params || {}

            set(
              (state) => ({
                calendar: {
                  ...state.calendar,
                  data: [],
                  isFetching: true,
                },
              }),
              undefined,
              'loadDates'
            )

            try {
              let {
                dateMiddleCalendarDateCacheKey: dateMiddleCacheKey,
                dateToCalendarDateCacheKey: dateToCacheKey,
                ...restParams
              } = requestParams

              let dateFrom = restParams?.date_from ?? undefined

              const { data, status }: AxiosResponse<IDate[]> = await getDatesApiRequest({
                ...restParams,
                date_from: !restParams.initial ? restParams.date_from : undefined,
                date_to: !restParams.initial ? restParams.date_to : undefined,
                initial: undefined,
              })

              let calendarDates: IDate[] = []
              let calendarDateToSet: Date

              if (status === 200) {
                if (restParams.initial) {
                  calendarDates = getCalendarDates(data)

                  const [initialCalendarDate] = calendarDates

                  calendarDateToSet = initialCalendarDate
                    ? new Date(initialCalendarDate.date)
                    : new Date()

                  const { dateFromCalendarDate, dateMiddleCalendarDate, dateToCalendarDate } =
                    getRangeForCalendarDatesRequest(calendarDateToSet, [], true)

                  const {
                    dateFromCalendarDateRequest,
                    dateMiddleCalendarDateCacheKey,
                    dateToCalendarDateCacheKey,
                  } = getDateKeysForCache(
                    dateFromCalendarDate,
                    dateMiddleCalendarDate,
                    dateToCalendarDate
                  )

                  dateFrom = dateFromCalendarDateRequest
                  dateMiddleCacheKey = dateMiddleCalendarDateCacheKey
                  dateToCacheKey = dateToCalendarDateCacheKey

                  const { hotel, resetCalendar, roomType, travelDate, visitType } =
                    get() as BookingFormStore

                  const shouldResetCalendar = await validateCalendarDatesRequest({
                    allAvailableDates: calendarDates,
                    hotel,
                    initial: true,
                    roomType,
                    travelDate,
                    visitType,
                  })

                  if (shouldResetCalendar) {
                    resetCalendar()
                  }
                }

                // set dates loaded successfully
                const payload = {
                  dates: data,
                  datesCache: {
                    [dateFrom as string]: dateFrom ? getDatesForCalendarDate(dateFrom, data) : [],
                    [dateMiddleCacheKey as string]: dateMiddleCacheKey
                      ? getDatesForCalendarDate(dateMiddleCacheKey, data)
                      : [],
                    [dateToCacheKey as string]: dateToCacheKey
                      ? getDatesForCalendarDate(dateToCacheKey, data)
                      : [],
                  },
                } as IDateWithCache

                const dateArrayFromResponse = payload.dates as IDate[]
                // const dateArrayInStore = state.calendar.data

                const {
                  calendar: { datesCache },
                  changeCalendarDate,
                } = get() as BookingFormStore

                const dateRangesCacheInStore = datesCache
                const requestedDateRanges = payload.datesCache as Record<string, IDate[]>

                set(
                  (state) => ({
                    calendar: {
                      ...state.calendar,
                      data: dateArrayFromResponse,
                      datesCache: {
                        ...dateRangesCacheInStore,
                        ...requestedDateRanges,
                      },
                      isDidInvalid: false,
                      isFetching: false,
                    },
                  }),
                  undefined,
                  'loadDates'
                )

                if (restParams.initial) {
                  const [initialCalendarDate] = calendarDates

                  const calendarDateToSet = initialCalendarDate
                    ? new Date(initialCalendarDate.date)
                    : new Date()

                  changeCalendarDate(calendarDateToSet)
                }
              }
            } catch (error: any) {
              set(
                (state) => ({
                  calendar: {
                    ...state.calendar,
                    data: [],
                    isFetching: false,
                  },
                }),
                undefined,
                'loadDatesError'
              )
              const { addNotification } = get() as BookingFormStore
              handleApiErrorAndShowNotification(error, addNotification)
            }
          },
          loadDatesCheckout: async (params: IDatesCheckoutParamsQuery) => {
            try {
              set(
                (state) => ({
                  calendar: {
                    ...state.calendar,
                    checkout: [],
                    isFetching: true,
                  },
                }),
                undefined,
                'loadDatesCheckout'
              )

              const { data, status }: AxiosResponse<IDateCheckout[]> =
                await getDatesCheckoutApiRequest(params)
              if (status === 200) {
                set(
                  (state) => ({
                    calendar: {
                      ...state.calendar,
                      checkout: data,
                      isFetching: false,
                    },
                  }),
                  undefined,
                  'loadDatesCheckoutSuccess'
                )
              }
            } catch (error: any) {
              set(
                (state) => ({
                  calendar: {
                    ...state.calendar,
                    checkout: [],
                    isFetching: false,
                  },
                }),
                undefined,
                'loadDatesCheckoutError'
              )

              const { addNotification } = get() as BookingFormStore
              handleApiErrorAndShowNotification(error, addNotification)
            }
          },
          loadHotel: async (hotelId: Identificator) => {
            try {
              set(
                (state) => ({
                  hotelDialog: {
                    ...state.hotelDialog,
                    isFetching: true,
                  },
                }),
                undefined,
                'loadHotel'
              )

              const { data, status }: AxiosResponse<IHotel> = await getHotelApiRequest(hotelId)
              if (status === 200) {
                set(
                  (state) => ({
                    hotelDialog: {
                      ...state.hotelDialog,
                      data: data || null,
                      isFetching: false,
                    },
                  }),
                  undefined,
                  'loadHotelSuccess'
                )
              }
            } catch (error: any) {
              set(
                (state) => ({
                  hotelDialog: {
                    ...state.hotelDialog,
                    data: null,
                    isDidInvalid: false,
                    isFetching: false,
                  },
                }),
                undefined,
                'loadHotelError'
              )
              const { addNotification } = get() as BookingFormStore
              handleApiErrorAndShowNotification(error, addNotification)
            }
          },
          loadHotelRoomTypes: async (hotelId: Identificator, params: IHotelDetailParamsQuery) => {
            const requestParams = params || {}

            try {
              set(
                {
                  isFetchingHotelRoomTypes: true,
                  roomTypes: [],
                },
                undefined,
                'loadHotelRoomTypes'
              )

              const { data, status }: AxiosResponse<IHotel> = await getHotelApiRequest(
                hotelId,
                requestParams
              )
              if (status === 200) {
                const { guest, language } = get() as BookingFormStore

                const roomTypes = (data?.roomTypes || [])
                  .filter(
                    (item) => getPriceOrNull(item.lowest_price, 'amount_per_night', language) !== 0
                  )
                  .filter((item) => (guest !== null ? item.beds === guest : true))

                set(
                  {
                    roomTypes,
                  },
                  undefined,
                  'loadHotelRoomTypesSuccess'
                )
              }
            } catch (error: any) {
              set(
                {
                  roomTypes: [],
                },
                undefined,
                'loadHotelRoomTypesError'
              )
              const { addNotification } = get() as BookingFormStore
              handleApiErrorAndShowNotification(error, addNotification)
            } finally {
              set(
                {
                  isFetchingHotelRoomTypes: false,
                },
                undefined,
                'loadHotelRoomTypesFinally'
              )
            }
          },
          loadHotels: async (params: IHotelsParamsQuery) => {
            const requestParams = params || {}

            try {
              set(
                {
                  hotels: [],
                  isFetchingHotels: true,
                },
                undefined,
                'loadHotels'
              )

              const { data, status }: AxiosResponse<IHotel[]> =
                await getHotelsApiRequest(requestParams)
              if (status === 200) {
                set(
                  {
                    hotels: data.map((hotel) => {
                      return {
                        ...hotel,
                        id: Number(hotel.id),
                      }
                    }),
                  },
                  undefined,
                  'loadHotelsSuccess'
                )
              }
            } catch (error: any) {
              set(
                {
                  hotels: [],
                },
                undefined,
                'loadHotelsError'
              )
              const { addNotification } = get() as BookingFormStore
              handleApiErrorAndShowNotification(error, addNotification)
            } finally {
              set(
                {
                  isFetchingHotels: false,
                },
                undefined,
                'loadHotelsFinally'
              )
            }
          },
          loadLowestPrice: async (params: URLSearchParams) => {
            try {
              const { data, status }: AxiosResponse<IPrice> = await getLowestPrice(params)

              if (status === 200) {
                set(
                  {
                    lowestPrice: data,
                  },
                  undefined,
                  'loadLowestPrice'
                )
              }
            } catch (error: any) {
              set(
                {
                  lowestPrice: null,
                },
                undefined,
                'loadLowestPriceError'
              )
              const { addNotification } = get() as BookingFormStore
              handleApiErrorAndShowNotification(error, addNotification)
            }
          },
          loadLoyaltyProgram: async () => {
            try {
              set(
                {
                  loyaltyProgram: [],
                },
                undefined,
                'loadLoyaltyProgram'
              )
              const { data, status }: AxiosResponse<ILoyaltyProgram[]> = await getLoyaltyProgram()

              if (status === 200) {
                set(
                  {
                    loyaltyProgram: data,
                  },
                  undefined,
                  'loadLoyaltyProgramSuccess'
                )
              }
            } catch (error: any) {
              set(
                {
                  loyaltyProgram: [],
                },
                undefined,
                'loadLoyaltyProgramError'
              )
              const { addNotification } = get() as BookingFormStore
              handleApiErrorAndShowNotification(error, addNotification)
            }
          },
          loadPrice: async (params: URLSearchParams) => {
            try {
              const { data, status }: AxiosResponse<IPrice> = await getPriceApiRequest(params)
              if (status === 200) {
                set(
                  {
                    price: data,
                  },
                  undefined,
                  'loadPrice'
                )
              }
            } catch (error: any) {
              set(
                {
                  price: null,
                },
                undefined,
                'loadPriceError'
              )
              const { addNotification } = get() as BookingFormStore
              handleApiErrorAndShowNotification(error, addNotification)
            }
          },
          loadVisitCategories: async (params: IVisitTypeCategoryParamsQuery) => {
            const requestParams = params || {}

            try {
              set(
                {
                  isFetchingVisitCategories: true,
                  visitCategories: [],
                },
                undefined,
                'loadVisitCategories'
              )

              const { data, status }: AxiosResponse<IVisitCategory[]> =
                await getVisitCategoriesApiRequest(requestParams)
              if (status === 200) {
                set(
                  {
                    visitCategories: data,
                  },
                  undefined,
                  'loadVisitCategoriesSuccess'
                )
              }
            } catch (error: any) {
              const { addNotification } = get() as BookingFormStore
              handleApiErrorAndShowNotification(error, addNotification)
            } finally {
              set(
                {
                  isFetchingVisitCategories: false,
                },
                undefined,
                'loadVisitCategoriesFinally'
              )
            }
          },
          removeNotification: (notificationToRemove: INotification) =>
            set(
              (state) => ({
                notifications: state.notifications.filter(
                  (notification) => notification !== notificationToRemove
                ),
              }),
              undefined,
              'removeNotification'
            ),
          resetApplication: () =>
            set(
              (state) => ({
                ...defaultInitialState,
                affiliate: state.affiliate,
                currency: state.currency,
                destinations: state.destinations,
                language: state.language,
                languageInitialized: state.languageInitialized,
              }),
              undefined,
              'resetApplication'
            ),
          resetCalendar: () =>
            set(
              {
                calendar: defaultInitialState.calendar,
                travelDate: defaultInitialState.travelDate,
              },
              undefined,
              'resetCalendar'
            ),
          saveChildren: (data: IChild[]) =>
            set(
              {
                children: data as IChild[],
              },
              undefined,
              'saveChildren'
            ),
          saveGuests: (data: GuestFormValues) =>
            set(
              {
                guestsData: data as GuestsFormData,
              },
              undefined,
              'saveGuests'
            ),
          savePets: (data: IPet[]) =>
            set(
              {
                pets: data as IPet[],
              },
              undefined,
              'savePets'
            ),
          sendBookingRequest: async () => {
            const {
              affiliate: { affilUserName: storedAffilUserName },
              children,
              currency: storedCurrency,
              demand: storedDemand,
              guestsData,
              hotel: storedHotel,
              language: storedLanguage,
              personalData,
              pets,
              roomType: storedRoomType,
              servicesData,
              setLoader,
              summaryData,
              travelDate: storedTravelDate,
              visitType: storedVisitType,
            } = get() as BookingFormStore

            setLoader(Loaders.BOOKING)

            const currency: ICurrency = storedCurrency
            const language: ILanguage = storedLanguage
            const demand: boolean = storedDemand
            const guestsFormData: GuestsFormData = guestsData
            const hotel: IHotel = storedHotel!
            const personalFormData: PersonalFormData = personalData
            const roomType: IRoomType = storedRoomType!
            const servicesFormData: ServiceFormData = servicesData
            const summaryFormData: SummaryFormData = summaryData
            const travelDate: ITravelDate = storedTravelDate
            const visitType: IVisitType = storedVisitType!
            const affilUserName: string | null = storedAffilUserName

            const values: IBookingRequest = {
              billed_to: {
                address: !demand
                  ? {
                      city: personalFormData.city,
                      country: personalFormData.country,
                      house_number: personalFormData.house_number,
                      street: personalFormData.street,
                      zip_code: personalFormData.zip_code,
                    }
                  : undefined,
                consent_marketing: summaryFormData.marketing,
                email: personalFormData.email,
                first_name: personalFormData.first_name,
                gender: personalFormData.gender,
                last_name: personalFormData.last_name,
                phone: personalFormData.phone,
              },
              binding_booking: !demand,
              currency,
              customer_note: createCustomerNote(servicesFormData.note, children, pets),
              date_from: travelDate.from ? format(travelDate.from, DATE_FORMAT_YYYY_MM_DD) : '',
              date_to: travelDate.to ? format(travelDate.to, DATE_FORMAT_YYYY_MM_DD) : '',
              guests: prepareGuestDataForBookingRequest(
                guestsFormData.guests,
                servicesFormData
              ) as IGuest[],
              hotel_id: parseInt(hotel.id as string),
              room_type_id: parseInt(roomType.id as string),
              visit_type_id: parseInt(visitType.id as string),
              ...(affilUserName && {
                affilUserName,
              }),
            }

            try {
              const { status }: AxiosResponse<IBookingResponse> = await sendBookingApiRequest(
                values,
                language
              )
              if (status === 200) {
                redirectTo(PathEnum.STEP_6, {}, language).then(() => {
                  setLoader(Loaders.BOOKING)
                })
              }
            } catch (err) {
              setLoader(Loaders.BOOKING)

              const errorModifications = {
                level: MESSAGE_LEVELS.FATAL,
                message: `URGENT - Booking of visit didn't proceed.`,
                name: 'URGENT',
              }
              const customizedError = customizeError(err as Error, errorModifications)
              const { addNotification } = get() as BookingFormStore
              handleApiErrorAndShowNotification(customizedError, addNotification)
            }
          },
          setAffiliateUsername: (affilUserName: string) =>
            set(
              {
                affiliate: {
                  affilUserName,
                  timestamp: Date.now(),
                },
              },
              undefined,
              'setAffiliateUsername'
            ),
          setAppInitialized: (isAppInitialized: boolean) =>
            set(
              {
                appInitialized: isAppInitialized,
              },
              undefined,
              'setAppInitialized'
            ),
          setAppInitializedAndValidated: (isAppInitializedAndValidated: boolean) =>
            set(
              {
                appInitializedAndValidated: isAppInitializedAndValidated,
              },
              undefined,
              'setAppInitializedAndValidated'
            ),
          setCalendarMode: (mode: CalendarModeEnum) =>
            set(
              (state) => ({
                calendar: {
                  ...state.calendar,
                  mode,
                },
              }),
              undefined,
              'setCalendarMode'
            ),
          setCalendarTravelDate: async (calendarTravelDateData: ITravelDate) => {
            set((state) => ({
              calendar: {
                ...state.calendar,
                price: defaultInitialState.calendar.price,
                travelDate: calendarTravelDateData,
              },
            }))

            const { hotel, roomType, visitType } = get() as BookingFormStore
            const { from, to } = calendarTravelDateData

            if (visitType?.id && hotel?.id && roomType?.id && from && to) {
              const queryParams = new URLSearchParams()
              queryParams.append('visit_type_id', visitType.id.toString())
              queryParams.append('room_type_id', roomType.id.toString())
              queryParams.append('date_from', format(from as Date, DATE_FORMAT_YYYY_MM_DD))
              queryParams.append('date_to', format(to as Date, DATE_FORMAT_YYYY_MM_DD))

              try {
                const { data, status }: AxiosResponse<IPrice> =
                  await getPriceApiRequest(queryParams)
                if (status === 200) {
                  set(
                    (state) => ({
                      calendar: {
                        ...state.calendar,
                        price: data,
                      },
                    }),
                    undefined,
                    'setCalendarModeSuccess'
                  )
                }
              } catch (error: any) {
                set(
                  (state) => ({
                    calendar: {
                      ...state.calendar,
                      price: null,
                    },
                  }),
                  undefined,
                  'setCalendarModeError'
                )
                const { addNotification } = get() as BookingFormStore
                handleApiErrorAndShowNotification(error, addNotification)
              }
            }
          },
          setDeletingQueryParameter: (isDeleting: boolean) =>
            set(
              {
                isDeletingQueryParameter: isDeleting,
              },
              undefined,
              'setDeletingQueryParameter'
            ),
          setDemand: (isDemand: boolean) =>
            set(
              {
                demand: isDemand,
              },
              undefined,
              'setDemand'
            ),
          setDestinations: (destinations) =>
            set(
              {
                destinations,
              },
              undefined,
              'setDestinations'
            ),
          setHotelDialogData: (data: IHotel | null) =>
            set(
              (state) => ({
                hotelDialog: {
                  ...state.hotelDialog,
                  data,
                },
              }),
              undefined,
              'setHotelDialogData'
            ),
          setInitData: (initDataBookingConfigResponse: IInitDataSuccessPayload) => {
            const {
              availability,
              date_from,
              date_to,
              destination,
              guest,
              hotel,
              price,
              room_type,
              visit_type,
            } = initDataBookingConfigResponse

            const { demand } = get() as BookingFormStore

            const travelDate = {
              from: date_from,
              to: date_to,
            }

            set(
              (state) => ({
                calendar: {
                  ...state.calendar,
                  price: defaultInitialState.calendar.price,
                  travelDate,
                },
                demand: availability ? availability === 'ON_DEMAND' : demand,
                destination: destination ? { ...destination, id: +destination.id } : null,
                guest: guest,
                hotel: hotel ? { ...hotel, id: +hotel.id } : null,
                price: price || null,
                roomType: room_type || null,
                travelDate,
                visitType: visit_type || null,
              }),
              undefined,
              'setInitData'
            )
          },
          setIsFetching: (isFetching: boolean) =>
            set(
              {
                isFetching,
              },
              undefined,
              'setIsFetching'
            ),
          setIsFormFilled: (isFormFilled: boolean) =>
            set(
              {
                isFormFilled,
              },
              undefined,
              'setIsFormFilled'
            ),
          setLoader: (loader: keyof ILoaders) =>
            set(
              (state) => ({
                loaders: {
                  ...state.loaders,
                  [loader]: !state.loaders[loader],
                },
              }),
              undefined,
              'setLoader'
            ),
          setLoadingImages: (loadingImagesCount: number) =>
            set(
              (state) => ({
                images: { ...state.images, loading: loadingImagesCount },
              }),
              undefined,
              'setLoader'
            ),
          setPersonalData: (data: PersonalDataFormValues | BasicContactFormValues) => {
            set(
              {
                personalData: data as PersonalFormData,
              },
              undefined,
              'setPersonalData'
            )
          },
          setRoomTypeDialogData: (data: IRoomType | null) =>
            set(
              (state) => ({
                roomTypeDialog: {
                  ...state.roomTypeDialog,
                  data,
                },
              }),
              undefined,
              'setRoomTypeDialogData'
            ),
          setRouteQueryParams: (routeQueryParams: IRouteQueryParamsWithDynamicParams) =>
            set(
              {
                routeQueryParams,
              },
              undefined,
              'setRouteQueryParams'
            ),
          setServicesData: (data: ServicesFormValues) =>
            set(
              {
                servicesData: {
                  loyaltyPrograms: data.loyaltyPrograms ?? [],
                  note: data.note ?? null,
                },
              },
              undefined,
              'setServicesData'
            ),
          setStep: (value: number) =>
            set(
              (state) => ({
                stepper: {
                  ...state.stepper,
                  step: value,
                },
              }),
              undefined,
              'setStep'
            ),
          setSummaryData: (data: SummaryFormData) =>
            set(
              {
                summaryData: data,
              },
              undefined,
              'setSummaryData'
            ),
          setTravelDate: async (travelDateData: ITravelDate) => {
            set({
              travelDate: travelDateData,
            })

            if (
              travelDateData &&
              travelDateData?.from instanceof Date &&
              travelDateData?.to instanceof Date
            ) {
              const { hotel, roomType, visitType } = get() as BookingFormStore
              const { from, to } = travelDateData

              try {
                const { data, status }: AxiosResponse<IDate[]> = await getDatesApiRequest({
                  date_from: format(from as Date, DATE_FORMAT_YYYY_MM_DD),
                  date_to: format(to as Date, DATE_FORMAT_YYYY_MM_DD),
                  hotel_id: hotel?.id || undefined,
                  room_type_id: roomType?.id || undefined,
                  visit_type_id: visitType?.id || undefined,
                })
                if (
                  status === 200 &&
                  data.filter((item) => item.availability === 'ON_DEMAND').length > 0
                ) {
                  set(
                    {
                      onlyDemand: true,
                    },
                    undefined,
                    'setTravelDate'
                  )
                } else {
                  set(
                    {
                      onlyDemand: false,
                    },
                    undefined,
                    'setTravelDate'
                  )
                }
              } catch (error: any) {
                set(
                  {
                    onlyDemand: false,
                  },
                  undefined,
                  'setTravelDateError'
                )
                const { addNotification } = get() as BookingFormStore
                handleApiErrorAndShowNotification(error, addNotification)
              }
            }
          },
          setVisitTypeDialogData: (data: IVisitType | null) =>
            set(
              (state) => ({
                visitTypeDialog: {
                  ...state.visitTypeDialog,
                  data,
                },
              }),
              undefined,
              'setVisitTypeDialogData'
            ),
        }),
        {
          name: 'affiliate-storage',
          onRehydrateStorage: () => (state) => {
            if (state) {
              const currentTime = Date.now()
              if (currentTime - state.affiliate.timestamp > EXPIRATION_TIME) {
                state.affiliate = affiliateInitialData
              }
            }
          },
          partialize: (state) => ({ affiliate: state.affiliate }),
        }
      ),
      { name: 'BookingFormStore' }
    )
  )
}

const validateCalendarDatesRequest = async (payload: {
  allAvailableDates: IDate[]
  hotel: IHotel | null
  initial?: boolean
  roomType: IRoomType | null
  travelDate: ITravelDate | null
  visitType: IVisitType | null
}) => {
  const {
    allAvailableDates,
    hotel,
    initial = false,
    roomType,
    travelDate,
    visitType,
  } = payload || {}

  if (allAvailableDates) {
    let shouldResetCalendar = false

    if (travelDate && isDate(travelDate.from) && isDate(travelDate.to)) {
      const { from, to } = travelDate

      const fromCalendarTravelDateFormat = format(from as Date, DATE_FORMAT_YYYY_MM_DD)
      const toCalendarTravelDateFormat = format(to as Date, DATE_FORMAT_YYYY_MM_DD)

      const findValidDateFromAvailable = allAvailableDates.find(
        (item) => item.date === fromCalendarTravelDateFormat
      )

      if (findValidDateFromAvailable) {
        const { data, status }: AxiosResponse<IDateCheckout[]> = await getDatesCheckoutApiRequest({
          hotel_id: hotel?.id || undefined,
          room_type_id: roomType?.id || undefined,
          start_date: fromCalendarTravelDateFormat,
          visit_type_id: visitType?.id || undefined,
        })

        if (status !== 200 || !data.find((item) => item.date === toCalendarTravelDateFormat)) {
          shouldResetCalendar = true
        }
      } else {
        shouldResetCalendar = true
      }
    } else if (!initial) {
      shouldResetCalendar = true
    }

    return shouldResetCalendar
  }
}

export const handleApiErrorAndShowNotification = (
  error: unknown,
  addNotification: (notification: INotification) => void
) => {
  handleApiError(error, (notification: INotification) => addNotification(notification))
}
