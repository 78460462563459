import Router from 'next/router'
import { ParsedUrlQuery, ParsedUrlQueryInput } from 'querystring'
import * as React from 'react'
import TagManager from 'react-gtm-module'
import removeAccents from 'remove-accents'
import { getDateForDatePickerComponent } from '@utils/dates'
import { getDefaultDestinationIdOrNull, getDestinationById } from '@utils/destination'
import { getLayoutConfig } from './config'
import { SingleGuestFormValues } from './forms/guests/useGuestsForm'
import {
  CurrencyEnum,
  GuestFormData,
  ICurrency,
  IDestination,
  IHotel,
  IInitDataParametersQuery,
  ILang,
  ILanguage,
  IPrice,
  IPriceBreakdownDiscount,
  IRoomType,
  IRouteQueryParams,
  IRouteQueryParamsWithDynamicParams,
  IVisitType,
  LanguageEnum,
  PersonalFormData,
} from './store/data'

export const getKeyLang = (language: ILanguage): keyof ILang => {
  if (language === 'cs') {
    return 'cs_CZ'
  }
  return language
}

export const getCurrencyFromLanguage = (language: ILanguage): ICurrency =>
  language === LanguageEnum.CS ? CurrencyEnum.CZK : CurrencyEnum.EUR

export const getLang = (data: any, language: ILanguage): string => {
  const getLangFallback = (obj: any): string => obj?.de || obj?.cs_CZ || obj?.en || obj?.ru || ''
  return data?.[getKeyLang(language)] || getLangFallback(data)
}

export const getValidGuestNumberOrNull = (guest: string | number | null | undefined) => {
  const guestNumber = Number(guest)

  // allowed guest count is 1 or 2
  return !isNaN(guestNumber) && guestNumber > 0 && guestNumber < 3 ? guestNumber : null
}

const getDefaultDestinationOrNull = (destinationsData: IDestination[]) => {
  const defaultDestination = getDefaultDestinationIdOrNull()

  if (!defaultDestination) {
    return null
  }

  return getDestinationById(Number(defaultDestination), destinationsData)
}

export const getBookingConfigDestinationQueryParameter = (
  destinationQueryParam: string | null,
  language: ILanguage,
  destinationsData: IDestination[]
) => {
  if (destinationQueryParam) {
    return destinationQueryParam
  }

  const defaultDestination = getDefaultDestinationOrNull(destinationsData)

  if (!defaultDestination) {
    return null
  }

  // Note: The default destination id should be set for a specific location in .env (e.g., frantiskovylazne), not for spabooking (has multiple destinations and default destination id is null)
  return getLang(defaultDestination.name, language)
}

export const getValidDestinationOrNull = (
  destinationsData: IDestination[],
  requestedDestination: IDestination | null
): IDestination | null => {
  if (!requestedDestination) {
    return getDefaultDestinationOrNull(destinationsData)
  }

  return requestedDestination
}

export const getLangIso = (iso: keyof ILang | 'cs'): ILanguage => {
  if (iso === 'cs_CZ') {
    return LanguageEnum.CS
  }

  if (['en', 'de', 'cs', 'ru'].includes(iso)) {
    return iso as ILanguage
  }

  return 'de'
}

export const getRouteQueryParameters = (query: ParsedUrlQuery) => {
  const queryParams: Record<string, string | null | undefined> = {}

  for (const key in query) {
    if (Object.prototype.hasOwnProperty.call(query, key)) {
      const value = query[key]

      if (key === 'lang') {
        // Ensure that the value for lang is one of the keys of ILang or null
        queryParams.lang = typeof value === 'string' ? (value as keyof ILang) : null
      } else if (key !== 'lang') {
        // Handle other keys normally. Next.js sometimes returns query params as arrays, this takes the first value if that's the case
        queryParams[key as keyof Omit<IRouteQueryParams, 'lang'>] = Array.isArray(value)
          ? value[0]
          : value
      }
    }
  }

  return queryParams
}

const isPriceNumber = (price: number | string) => {
  const num = typeof price === 'string' ? parseFloat(price) : price
  return !isNaN(num) && isFinite(num)
}

export const getPriceOrNull = (data: any, field: string, language: ILanguage): number | null => {
  const price = data?.[getKeyLang(language)]?.[field]

  return isPriceNumber(price) ? price : null
}

export const getPriceNumber = (data: any, field: string, language: ILanguage): number =>
  getPriceOrNull(data, field, language) || 0

export const getBasePrice = (data: any, language: ILanguage): number => {
  const basePrice = data?.[getKeyLang(language)]?.price_breakdown?.base_price

  return isPriceNumber(basePrice) ? basePrice : 0
}

export const getAllDiscounts = (data: any, language: ILanguage) => {
  return data?.[getKeyLang(language)]?.price_breakdown?.discounts ?? []
}

export const getTotalDiscountedPrice = (data: any, language: ILanguage): number => {
  const allDiscounts = getAllDiscounts(data, language)

  const totalDiscountedPrice = allDiscounts.reduce(
    (accumulator: number, discount: IPriceBreakdownDiscount) => {
      return accumulator - discount.price_discount
    },
    0
  )

  return totalDiscountedPrice
}

export enum PathEnum {
  STEP_1 = '/',
  STEP_2 = '/personal-data',
  STEP_3 = '/guests',
  STEP_4 = '/services',
  STEP_5 = '/summary',
  STEP_6 = '/finish',
}

export const redirectTo = async (
  path: PathEnum,
  query: IRouteQueryParamsWithDynamicParams,
  lang: ILanguage
): Promise<void> => {
  const encodedQuery = encodeRouteQueryValues(query)
  const routeQuery = { ...encodedQuery, lang: getKeyLang(lang) }
  await Router.push({ pathname: path, query: routeQuery as ParsedUrlQueryInput })
}

export const encodeRouteQueryValues = (routeQuery: IRouteQueryParamsWithDynamicParams) => {
  const encodedRouteQuery: Record<string, string> = {}

  for (const [key, value] of Object.entries(routeQuery)) {
    if (value) {
      encodedRouteQuery[key] = removeAccents(value.toString())
    }
  }

  return encodedRouteQuery
}

export const sanitizeBookingParams = (params: IInitDataParametersQuery) => {
  const { destination_name, guest, hotel_name, room_type_category, visit_type_name } = params

  return {
    ...params,
    destination_name: destination_name ? removeAccents(destination_name) : null,
    guest: getValidGuestNumberOrNull(guest),
    hotel_name: hotel_name ? removeAccents(hotel_name) : null,
    room_type_category: room_type_category ? removeAccents(room_type_category) : null,
    visit_type_name: visit_type_name ? removeAccents(visit_type_name) : null,
  }
}

const getUniqueId = (): string => {
  let uid = window.sessionStorage.getItem('uid_user')
  if (!uid) {
    uid = Math.random().toString(36).substr(2, 18)
    window.sessionStorage.setItem('uid_user', uid)
  }
  return uid
}

export const reportConversion = (
  event: 'beginCheckout' | 'conversionDemand' | 'conversionBooking',
  language: ILanguage,
  price: IPrice | null,
  currency: ICurrency,
  visitType: IVisitType | null,
  roomType: IRoomType | null,
  hotel: IHotel | null
): void => {
  if (process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID) {
    const id = getUniqueId()
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          currencyCode: currency,
          purchase: {
            actionField: {
              id,
              revenue: getPriceNumber(price, 'amount', language),
            },
            products: [
              {
                category: getLang(roomType?.category, language),
                id: visitType?.id || null,
                name: getLang(visitType?.name, language),
                price: getPriceNumber(price, 'amount', language),
                quantity: 1,
              },
            ],
          },
        },
        event,
        hotel: hotel?.name,
        items: [
          {
            currency: currency,
            item_id: visitType?.id || null,
            item_name: getLang(visitType?.name, language),
            item_variant: getLang(roomType?.category, language),
            price: getPriceNumber(price, 'amount', language),
            quantity: 1,
          },
        ],
        room: getLang(roomType?.category, language),
        shipping: 0,
        transaction_id: id,
        value: getPriceNumber(price, 'amount', language),
        visit_type: getLang(visitType?.name, language),
      },
    })
  }
}

export const useGoogleTagManager = (): void => {
  const gtmId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || null
  React.useEffect(() => {
    if (gtmId) {
      TagManager.initialize({ gtmId })
    }
  }, [gtmId])
}

export const getCopyright = (): string => {
  const config = getLayoutConfig()

  return config.copyright
}

export const getTermsAndConditionsLink = (language: ILanguage): string => {
  if (language) {
    const config = getLayoutConfig()
    return getLang(config.links.tos, language)
  }

  return '#'
}

export const getPersonalDataProcessingLink = (language: ILanguage): string => {
  if (language) {
    const config = getLayoutConfig()
    return getLang(config.links.gdpr, language)
  }

  return '#'
}

export const getCustomAppInstanceLanguage = () => {
  const config = getLayoutConfig()

  // must have defined instance language and domains for redirect
  if (!!config.instanceLanguage && !!config.instanceLanguageDomains) {
    return config.instanceLanguage
  }

  return null
}

export const getInstanceLanguage = () => {
  const customAppInstanceLanguage = getCustomAppInstanceLanguage()

  if (!customAppInstanceLanguage) {
    return LanguageEnum.CS // default language
  }

  return customAppInstanceLanguage
}

export const getInstanceLanguageDomain = (language: ILanguage) => {
  const config = getLayoutConfig()

  return config?.instanceLanguageDomains?.[language] || '#'
}

export const getSupportedLayoutLocales = (): ILanguage[] => {
  const config = getLayoutConfig()
  return config.supportedLocales
}

export const getContactLink = (language: ILanguage): string => {
  if (language) {
    const config = getLayoutConfig()
    return getLang(config.links.contact, language)
  }

  return '#'
}

export const getHomepageLink = (language: ILanguage): string => {
  if (language) {
    const config = getLayoutConfig()
    return getLang(config.links.homepage, language)
  }

  return '#'
}

export const useCloseWithEscKey = (handler: () => void): void => {
  React.useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        handler()
      }
    }
    window.addEventListener('keydown', listener)
    return () => {
      window.removeEventListener('keydown', listener)
    }
  })
}

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: React.RefObject<T>,
  handler: () => void
): void {
  React.useEffect(() => {
    const listener = (event: MouseEvent) => {
      const el = ref?.current
      if (!el || el.contains(event.target as Node)) {
        return
      }
      handler()
    }
    document.addEventListener('mousedown', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler])
}

export const getGuestFullName = (guest: GuestFormData) => {
  const { first_name, last_name } = guest

  const trimmedFirstName = first_name?.trim()
  const trimmedLastName = last_name?.trim()

  if (trimmedFirstName && trimmedLastName) {
    return `${trimmedFirstName} ${trimmedLastName}`
  } else if (trimmedFirstName) {
    return trimmedFirstName
  } else if (trimmedLastName) {
    return trimmedLastName
  }

  return null
}

export const isGuestDefined = (
  guestData: Omit<GuestFormData, 'isEditing'> | SingleGuestFormValues
) => {
  return !!(
    guestData?.first_name &&
    guestData?.last_name &&
    guestData?.gender &&
    guestData?.birth_date
  )
}

export const getPersonalDataWithBirthdate = (personalData: PersonalFormData) => {
  return {
    ...personalData,
    birth_date: getDateForDatePickerComponent(personalData.birth_date),
  }
}
