import { AxiosResponse } from 'axios'
import { getKeyLang } from '../helpers'
import {
  IBookingRequest,
  IBookingResponse,
  IDate,
  IDateCheckout,
  IDatesCheckoutParamsQuery,
  IDatesParamsQuery,
  Identificator,
  IDestination,
  IHotel,
  IHotelDetailParamsQuery,
  IHotelsParamsQuery,
  IInitDataParametersQuery,
  IInitDataResponse,
  ILanguage,
  ILoyaltyProgram,
  IPrice,
  IVisitCategory,
  IVisitTypeCategoryParamsQuery,
} from '../store/data'
import axios from './axios'

const GET_ANOTHER_REQUEST_DEBOUNCE_INTERVAL = 10

let calendarDatesAbortController: AbortController | null = null
let calendarDatesDebounceTimer: NodeJS.Timeout | null = null

let hotelsAbortController: AbortController | null = null
let hotelsDebounceTimer: NodeJS.Timeout | null = null

let visitTypeCategoriesAbortController: AbortController | null = null
let visitTypeCategoriesDebounceTimer: NodeJS.Timeout | null = null

export const getDatesApiRequest = (params: IDatesParamsQuery): Promise<AxiosResponse<IDate[]>> => {
  if (calendarDatesDebounceTimer) {
    clearTimeout(calendarDatesDebounceTimer)
  }

  if (calendarDatesAbortController) {
    calendarDatesAbortController.abort()
  }

  return new Promise((resolve, reject) => {
    calendarDatesDebounceTimer = setTimeout(() => {
      calendarDatesAbortController = new AbortController()

      axios
        .get<IDate[]>('/calendar/dates', {
          params,
          signal: calendarDatesAbortController.signal,
        })
        .then((response) => {
          calendarDatesAbortController = null
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    }, GET_ANOTHER_REQUEST_DEBOUNCE_INTERVAL)
  })
}

export const getDatesCheckoutApiRequest = (
  params: IDatesCheckoutParamsQuery
): Promise<AxiosResponse<IDateCheckout[]>> =>
  axios.get<IDateCheckout[]>('/calendar/checkouts', { params })
export const getHotelApiRequest = (
  hotelId: Identificator,
  params?: IHotelDetailParamsQuery
): Promise<AxiosResponse<IHotel>> => {
  const config: { params?: IHotelDetailParamsQuery } = {}

  if (params && Object.keys(params).length > 0) {
    config.params = params
  }

  return axios.get<IHotel>(`/hotels/${hotelId}`, config)
}
export const getHotelsApiRequest = (
  params: IHotelsParamsQuery
): Promise<AxiosResponse<IHotel[]>> => {
  if (hotelsDebounceTimer) {
    clearTimeout(hotelsDebounceTimer)
  }

  if (hotelsAbortController) {
    hotelsAbortController.abort()
  }

  return new Promise((resolve, reject) => {
    hotelsDebounceTimer = setTimeout(() => {
      hotelsAbortController = new AbortController()

      axios
        .get<IHotel[]>('/hotels', {
          params: {
            ...params,
            include_room_types: params?.include_room_types ?? true,
          },
          signal: hotelsAbortController.signal,
        })
        .then((response) => {
          hotelsAbortController = null
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    }, GET_ANOTHER_REQUEST_DEBOUNCE_INTERVAL)
  })
}

export const getLowestPrice = (params: URLSearchParams): Promise<AxiosResponse<IPrice>> =>
  axios.get<IPrice>('/lowest-price', { params })
export const getLoyaltyProgram = (): Promise<AxiosResponse<ILoyaltyProgram[]>> =>
  axios.get<ILoyaltyProgram[]>('/loyalty-programs')
export const getPriceApiRequest = (params: URLSearchParams): Promise<AxiosResponse<IPrice>> =>
  axios.get<IPrice>('/price', { params })
export const getVisitCategoriesApiRequest = (
  params: IVisitTypeCategoryParamsQuery
): Promise<AxiosResponse<IVisitCategory[]>> => {
  return new Promise((resolve, reject) => {
    if (visitTypeCategoriesDebounceTimer) {
      clearTimeout(visitTypeCategoriesDebounceTimer)
    }

    if (visitTypeCategoriesAbortController) {
      visitTypeCategoriesAbortController.abort()
    }

    visitTypeCategoriesDebounceTimer = setTimeout(() => {
      visitTypeCategoriesAbortController = new AbortController()

      axios
        .get<IVisitCategory[]>('/visit-type-categories', {
          params: {
            ...params,
            include_visit_types: true,
          },
          signal: visitTypeCategoriesAbortController.signal,
        })
        .then((response) => {
          visitTypeCategoriesAbortController = null
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    }, GET_ANOTHER_REQUEST_DEBOUNCE_INTERVAL)
  })
}

export const getDestinations = (): Promise<AxiosResponse<IDestination[]>> =>
  axios.get<IDestination[]>('/destinations')
export const sendBookingApiRequest = (
  data: IBookingRequest,
  language: ILanguage
): Promise<AxiosResponse<IBookingResponse>> =>
  axios.post('/booking', data, { headers: { 'Accept-Language': getKeyLang(language) } })
export const getBookingConfiguration = (
  params: IInitDataParametersQuery
): Promise<AxiosResponse<IInitDataResponse>> => axios.get<IInitDataResponse>('/booking', { params })
