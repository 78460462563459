import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { PriceExplanationMark } from '@components/PriceInfo/PriceExplanationMark'
import { useIsChildrenOrPets } from '../../hooks/useIsChildrenOrPets'

export const PriceExplanationDescription = () => {
  const isChildrenOrPets = useIsChildrenOrPets()

  if (!isChildrenOrPets) return null

  return (
    <div className="text-small mt-1">
      <PriceExplanationMark />
      <span className="ml-1">
        <FormattedMessage
          defaultMessage="Price shown is for adults only. Additional charges may apply for children and pets."
          description="Footer price explanation info"
        />
      </span>
    </div>
  )
}
